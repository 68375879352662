.react-datepicker-popper {
  z-index: 20 !important;
  position: fixed;
}

.react-datepicker-wrapper {
  z-index: 10 !important;
  width: 100%;
}

.react-datepicker__month-container {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.33rem;
}

.react-datepicker__navigation--previous {
  border-right-color: #3d4852;
}

.react-datepicker__navigation--next {
  border-left-color: #3d4852;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #3490dc;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.SingleDatePicker_picker {
  z-index: 20;
}

.DateInput_input {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.5rem 0 0.5rem 0.25rem;
  font-weight: 400;
  font-size: 100%;
}

.DateInput {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
